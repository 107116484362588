<template>
  <div>
    <div :class="$style.home">
      <div :class="$style.header">
        <p :class="$style.title">评论列表</p>
        <div :class="$style.btn">
          <a-dropdown :trigger="['click']">
            <span :class="$style.intellect">
              <i class="ri-settings-line" />
              <span>审核机制</span>
            </span>
            <a-menu slot="overlay" class="list-drop">
              <a-menu-item>
                <bj-link :class="$style.question" @click="onAudit(0)">
                  智能审核
                  <a-tooltip title="由人工智能机器审核">
                    <i style="color: #5c5c5c !important" class="ri-question-line" />
                  </a-tooltip>
                  <a-radio :checked="audit_type === 0" class="pull-right" />
                </bj-link>
              </a-menu-item>
              <a-menu-item>
                <bj-link :class="$style.question" @click="onAudit(1)">
                  人工审核
                  <a-radio :checked="audit_type === 1" class="pull-right" />
                </bj-link>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <div :class="$style.linex" />
          <dustbin v-permission="'app.community.comment-recycle.view'" :count="recycleTotal" @refresh="refresh()" />
          <div :class="[$style.line, recycleTotal ? null : $style.lineno]" />
          <BjInput
            v-model="search.keyword"
            :placeholder="placeholder"
            :class="$style.input"
            @pressEnter="onAllSearch()"
          >
            <a-select slot="addonBefore" v-model="search.search_type" style="width: 100px">
              <a-select-option :value="0"> 评论内容 </a-select-option>
              <a-select-option :value="1"> 用户昵称 </a-select-option>
              <i slot="suffixIcon" class="ri-arrow-down-s-line suffix" />
            </a-select>
          </BjInput>
          <BjButton type="primary" @click="onAllSearch()">搜索</BjButton>
        </div>
      </div>
      <div v-if="!show" :class="$style.content">
        <div :class="$style.left">
          <div v-if="!showSearch" :class="$style.leftSearch">
            <span>社区帖子</span>
            <i class="ri-search-line" @click="showSearch = true" />
          </div>
          <div v-else :class="$style.leftSearchInput">
            <BjInput v-model="keyword" @pressEnter="onSearch()">
              <i slot="suffix" class="ri-search-line" @click="onSearch()" />
            </BjInput>
            <p v-if="showKeyword" class="mt-14">
              搜索到 ”<span class="primary">{{ showKeyword }}</span
              >“ 内容共 {{ total }}条：
            </p>
          </div>

          <div :class="$style.leftBox">
            <div
              v-for="item in data"
              :key="item.id"
              :class="[$style.leftItem, item.id === current.id ? $style.active : null]"
              @click="onChoose(item)"
            >
              <P :class="$style.name" :title="item.title" class="ellipsis2">{{ item.title }}</P>
              <p :class="$style.desc">
                <span :class="$style.time">{{ item.last_comment_time_format }}</span>
                <span :class="$style.num">
                  <span v-if="!item.is_read_comment && item.id !== current.id" :class="$style.state" />
                  {{ item.comment_num }}条留言
                </span>
              </p>
            </div>

            <a-pagination
              v-model="page"
              class="text-center mt-20"
              simple
              :total="total"
              :page-size.sync="pageSize"
              @change="getList"
            />
          </div>
        </div>

        <div :class="$style.right">
          <a-row :class="$style.rightHeader">
            <a-col span="12" :class="$style.name"> 评论内容 </a-col>
            <a-col span="12" :class="$style.btn" class="text-right">
              允许评论
              <a-switch v-if="current.id" v-model="comment_status" :class="$style.switch" @change="stateChange" />
            </a-col>
          </a-row>

          <div :class="$style.table">
            <a-row :class="$style.head">
              <a-col span="18">评论</a-col>
              <a-col span="6">管理</a-col>
            </a-row>
            <div v-if="!detailData.length" class="no-data">
              <div class="nodata">
                <img :src="oss + '/manage/media/state/no_comment.png'" alt="" />
                <p>暂无数据</p>
              </div>
            </div>
            <div v-for="item in detailData" :key="item.id" :class="$style.box">
              <div :class="$style.boxItem">
                <div :class="$style.boxItemAvatar">
                  <img :src="item.user.avatar" />
                  <span>{{ item.user.nickname }}</span>
                </div>
                <div :class="$style.boxItemBtn">
                  <BjTag
                    :class="$style.state"
                    :type="item.status ? (item.status === 1 ? 'primary' : 'danger') : 'default'"
                  >
                    {{ item.status ? (item.status === 1 ? '审核通过' : '审核不通过') : '审核中' }}
                  </BjTag>
                  <span :class="$style.collect">
                    <a-popconfirm
                      :title="item.is_star ? '确定取消置顶吗？' : '确定置顶吗？置顶的评论会显示在评论列表的最前面。'"
                      @confirm="onStar(item)"
                    >
                      <a-tooltip>
                        <template slot="title"> {{ item.is_star ? '取消置顶' : '评论置顶' }} </template>
                        <i class="ri-star-smile-line" :style="{ color: item.is_star !== 0 ? '#1470DF' : '#bbb' }" />
                      </a-tooltip>
                    </a-popconfirm>
                  </span>

                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <span v-permission="'app.community.comment-examine'" :class="$style.examine">
                      <span>审核</span>
                      <i class="ri-arrow-down-s-line" />
                    </span>

                    <a-menu slot="overlay" class="list-drop">
                      <a-menu-item>
                        <a-popconfirm
                          v-if="item.status !== 1"
                          title="确定审核通过吗？审核通过之后评论将会在前端展示。"
                          @confirm="onExamine(item, true)"
                        >
                          <bj-link> 审核通过 </bj-link>
                        </a-popconfirm>
                        <bj-link v-else :disabled="true"> 审核通过 </bj-link>
                      </a-menu-item>
                      <a-menu-item>
                        <a-popconfirm
                          v-if="item.status !== 2"
                          title="确定审核不通过吗？审核不通过的评论不会在前端展示。"
                          @confirm="onExamine(item, false)"
                        >
                          <bj-link> 审核不通过 </bj-link>
                        </a-popconfirm>
                        <bj-link v-else :disabled="true"> 审核不通过 </bj-link>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>

                  <a-popconfirm title="确定将评论设置为垃圾评论吗？垃圾评论不会在前端显示。" @confirm="onSpam(item)">
                    <bj-link v-permission="'app.community.comment-spam'" class="ml-4" type="primary">
                      {{ item.is_spam ? '取消垃圾' : '垃圾' }}
                    </bj-link>
                  </a-popconfirm>
                  <a-popconfirm
                    title="确定要删除评论吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。"
                    @confirm="onDelete(item)"
                  >
                    <bj-link v-permission="'app.community.comment-delete'" class="ml-4" type="danger"> 删除 </bj-link>
                  </a-popconfirm>
                </div>

                <div :class="$style.boxItemContent">
                  <p v-if="item.is_delete" :class="$style.error">【评论已删除】</p>
                  <p v-else-if="!item.is_delete && item.is_spam" :class="$style.italic">
                    <span :class="$style.error">【垃圾评论】</span>
                    {{ item.comment }}
                  </p>
                  <p v-else>{{ item.comment }}</p>
                </div>
                <div :class="$style.boxItemDesc">
                  <i class="ri-thumb-up-line" />
                  <span>{{ item.praise_num }}</span>
                  <span>{{ item.created_at }}</span>
                </div>
              </div>

              <div v-for="children in item.children" :key="children.id" :class="[$style.boxItem, $style.children]">
                <div :class="$style.boxItemAvatar">
                  <img :src="children.user.avatar" />
                  <span>{{ children.user.nickname }}</span>
                </div>
                <div :class="$style.boxItemBtn">
                  <BjTag
                    :class="$style.state"
                    :type="children.status ? (children.status === 1 ? 'primary' : 'danger') : 'default'"
                  >
                    {{ children.status ? (children.status === 1 ? '审核通过' : '审核不通过') : '审核中' }}
                  </BjTag>

                  <span :class="$style.collect">
                    <a-popconfirm
                      :title="
                        children.is_star ? '确定取消置顶吗？' : '确定置顶吗？置顶的评论会显示在评论列表的最前面。'
                      "
                      @confirm="onStar(children)"
                    >
                      <a-tooltip>
                        <template slot="title"> {{ children.is_star ? '取消置顶' : '评论置顶' }} </template>
                        <i class="ri-star-smile-line" :style="{ color: children.is_star !== 0 ? '#1470DF' : '#bbb' }" />
                      </a-tooltip>
                    </a-popconfirm>
                  </span>

                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <span v-permission="'app.community.comment-examine'" :class="$style.examine">
                      <span>审核</span>
                      <i class="ri-arrow-down-s-line" />
                    </span>

                    <a-menu slot="overlay" class="list-drop">
                      <a-menu-item>
                        <a-popconfirm
                          v-if="children.status !== 1"
                          title="确定审核通过吗？审核通过之后评论将会在前端展示。"
                          @confirm="onExamine(children, true)"
                        >
                          <bj-link> 审核通过 </bj-link>
                        </a-popconfirm>
                        <bj-link v-else :disabled="true"> 审核通过 </bj-link>
                      </a-menu-item>
                      <a-menu-item>
                        <a-popconfirm
                          v-if="children.status !== 2"
                          title="确定审核不通过吗？审核不通过的评论不会在前端展示。"
                          @confirm="onExamine(children, false)"
                        >
                          <bj-link> 审核不通过 </bj-link>
                        </a-popconfirm>
                        <bj-link v-else :disabled="true"> 审核不通过 </bj-link>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>

                  <a-popconfirm
                    title="确定将评论设置为垃圾评论吗？垃圾评论不会在前端显示。"
                    @confirm="onSpam(children)"
                  >
                    <bj-link v-permission="'app.community.comment-spam'" class="ml-4" type="primary">
                      {{ children.is_spam ? '取消垃圾' : '垃圾' }}
                    </bj-link>
                  </a-popconfirm>
                  <a-popconfirm
                    title="确定要删除评论吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。"
                    @confirm="onDelete(children)"
                  >
                    <bj-link v-permission="'app.community.comment-delete'" class="ml-4" type="danger"> 删除 </bj-link>
                  </a-popconfirm>
                </div>

                <div :class="$style.boxItemContent">
                  <p v-if="children.is_delete" :class="$style.error">【评论已删除】</p>
                  <p v-else-if="!children.is_delete && children.is_spam" :class="$style.italic">
                    <span :class="$style.error">【垃圾评论】</span>
                    {{ children.comment }}
                  </p>
                  <p v-else>{{ children.comment }}</p>
                </div>
                <div :class="$style.boxItemDesc">
                  <i class="ri-thumb-up-line" />
                  <span>{{ children.praise_num }}</span>
                  <span>{{ children.created_at }}</span>
                </div>
              </div>
            </div>

            <a-pagination
              v-model="detailPage"
              class="text-right mt-20 mr-10 mb-20"
              :total="detailTotal"
              :page-size.sync="detailPageSize"
              @change="getDetail"
            />
          </div>
        </div>
      </div>
      <div v-else :class="$style.list">
        <p :class="$style.title">
          搜索到“
          <span class="primary">{{ showAllKeyword }} </span>
          ”内容共{{ showTotal }}条：
        </p>
        <div v-if="!listData.length" class="no-data">
          <div class="nodata">
            <img :src="oss + '/manage/media/state/no_comment.png'" alt="" />
            <p>暂无数据</p>
          </div>
        </div>
        <div v-for="item in listData" :key="item.id" :class="$style.box">
          <div :class="$style.boxItem">
            <div :class="$style.boxItemAvatar">
              <img :src="item.user.avatar" />
              <span>{{ item.user.nickname }}</span>
            </div>
            <div :class="$style.boxItemBtn">
              <BjTag :class="$style.state" :type="item.status ? (item.status === 1 ? 'primary' : 'danger') : 'default'">
                {{ item.status ? (item.status === 1 ? '审核通过' : '审核不通过') : '审核中' }}
              </BjTag>

              <span :class="$style.collect">
                <a-popconfirm
                  :title="item.is_star ? '确定取消置顶吗？' : '确定置顶吗？置顶的评论会显示在评论列表的最前面。'"
                  @confirm="onStar(item)"
                >
                  <a-tooltip>
                    <template slot="title"> {{ item.is_star ? '取消置顶' : '评论置顶' }} </template>
                    <i class="ri-star-smile-line" :style="{ color: item.is_star !== 0 ? '#1470DF' : '#bbb' }" />
                  </a-tooltip>
                </a-popconfirm>
              </span>

              <a-dropdown :trigger="['click']" placement="bottomRight">
                <span v-permission="'app.community.comment-examine'" :class="$style.examine">
                  <span>审核</span>
                  <i class="ri-arrow-down-s-line" />
                </span>

                <a-menu slot="overlay" class="list-drop">
                  <a-menu-item>
                    <a-popconfirm
                      v-if="item.status !== 1"
                      title="确定审核通过吗？审核通过之后评论将会在前端展示。"
                      @confirm="onExamine(item, true)"
                    >
                      <bj-link> 审核通过 </bj-link>
                    </a-popconfirm>
                    <bj-link v-else :disabled="true"> 审核通过 </bj-link>
                  </a-menu-item>
                  <a-menu-item>
                    <a-popconfirm
                      v-if="item.status !== 2"
                      title="确定审核不通过吗？审核不通过的评论不会在前端展示。"
                      @confirm="onExamine(item, false)"
                    >
                      <bj-link> 审核不通过 </bj-link>
                    </a-popconfirm>
                    <bj-link v-else :disabled="true"> 审核不通过 </bj-link>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>

              <a-popconfirm title="确定将评论设置为垃圾评论吗？垃圾评论不会在前端显示。" @confirm="onSpam(item)">
                <bj-link v-permission="'app.community.comment-spam'" class="ml-4" type="primary">
                  {{ item.is_spam ? '取消垃圾' : '垃圾' }}
                </bj-link>
              </a-popconfirm>
              <a-popconfirm
                title="确定要删除评论吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。"
                @confirm="onDelete(item)"
              >
                <bj-link v-permission="'app.community.comment-delete'" class="ml-4" type="danger"> 删除 </bj-link>
              </a-popconfirm>
            </div>

            <div :class="$style.boxItemContent">
              <p v-if="item.is_delete" :class="$style.error">【评论已删除】</p>
              <p v-else-if="!item.is_delete && item.is_spam" :class="$style.italic">
                <span :class="$style.error">【垃圾评论】</span>
                {{ item.comment }}
              </p>
              <p v-else>{{ item.comment }}</p>
            </div>
            <div :class="$style.boxItemDesc">
              <i class="ri-thumb-up-line" />
              <span>{{ item.praise_num }}</span>
              <span>{{ item.created_at }}</span>
            </div>
          </div>

          <div v-for="children in item.children" :key="children.id" :class="[$style.boxItem, $style.children]">
            <div :class="$style.boxItemAvatar">
              <img :src="children.user.avatar" />
              <span>{{ children.user.nickname }}</span>
            </div>
            <div :class="$style.boxItemBtn">
              <BjTag
                :class="$style.state"
                :type="children.status ? (children.status === 1 ? 'primary' : 'danger') : 'default'"
              >
                {{ children.status ? (children.status === 1 ? '审核通过' : '审核不通过') : '审核中' }}
              </BjTag>

              <span :class="$style.collect">
                <a-popconfirm
                  :title="children.is_star ? '确定取消置顶吗？' : '确定置顶吗？置顶的评论会显示在评论列表的最前面。'"
                  @confirm="onStar(children)"
                >
                  <a-tooltip>
                    <template slot="title"> {{ children.is_star ? '取消置顶' : '评论置顶' }} </template>
                    <i class="ri-star-smile-line" :style="{ color: children.is_star !== 0 ? '#1470DF' : '#bbb' }" />
                  </a-tooltip>
                </a-popconfirm>
              </span>

              <a-dropdown :trigger="['click']" placement="bottomRight">
                <span v-permission="'app.community.comment-examine'" :class="$style.examine">
                  <span>审核</span>
                  <i class="ri-arrow-down-s-line" />
                </span>

                <a-menu slot="overlay" class="list-drop">
                  <a-menu-item>
                    <a-popconfirm
                      v-if="children.status !== 1"
                      title="确定审核通过吗？审核通过之后评论将会在前端展示。"
                      @confirm="onExamine(children, true)"
                    >
                      <bj-link> 审核通过 </bj-link>
                    </a-popconfirm>
                    <bj-link v-else :disabled="true"> 审核通过 </bj-link>
                  </a-menu-item>
                  <a-menu-item>
                    <a-popconfirm
                      v-if="children.status !== 2"
                      title="确定审核不通过吗？审核不通过的评论不会在前端展示。"
                      @confirm="onExamine(children, false)"
                    >
                      <bj-link> 审核不通过 </bj-link>
                    </a-popconfirm>
                    <bj-link v-else :disabled="true"> 审核不通过 </bj-link>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>

              <a-popconfirm title="确定将评论设置为垃圾评论吗？垃圾评论不会在前端显示。" @confirm="onSpam(children)">
                <bj-link v-permission="'app.community.comment-spam'" class="ml-4" type="primary">
                  {{ children.is_spam ? '取消垃圾' : '垃圾' }}
                </bj-link>
              </a-popconfirm>
              <a-popconfirm
                title="确定要删除评论吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。"
                @confirm="onDelete(children)"
              >
                <bj-link v-permission="'app.community.comment-delete'" class="ml-4" type="danger"> 删除 </bj-link>
              </a-popconfirm>
            </div>

            <div :class="$style.boxItemContent">
              <p v-if="children.is_delete" :class="$style.error">【评论已删除】</p>
              <p v-else-if="!children.is_delete && children.is_spam" :class="$style.italic">
                <span :class="$style.error">【垃圾评论】</span>
                {{ children.comment }}
              </p>
              <p v-else>{{ children.comment }}</p>
            </div>
            <div :class="$style.boxItemDesc">
              <i class="ri-thumb-up-line" />
              <span>{{ children.praise_num }}</span>
              <span>{{ children.created_at }}</span>
            </div>
          </div>
        </div>
        <a-pagination
          v-model="listPage"
          class="text-right mt-20 mr-10"
          :total="listTotal"
          :page-size.sync="listPageSize"
          @change="getSearchDetail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { communityCommentService } from '@/service'

import dustbin from './dustbin.vue'

const service = new communityCommentService()

export default {
  name: 'Home',
  components: {
    dustbin,
  },
  data() {
    return {
      search: {
        keyword: null,
        search_type: 0,
      },
      keyword: null,
      recycleTotal: 0,
      show: false, // 整体搜索显示状态
      showSearch: false, // 标题搜索
      showKeyword: null, // 标题搜索后显示搜索内容
      comment_status: false, // 允许评论
      current: {},
      // 正常左边的数据
      data: [],
      page: 1,
      pageSize: 20,
      total: 0,
      // 详情
      detailData: [],
      detailPage: 1,
      detailPageSize: 20,
      detailTotal: 0,
      // 搜索相关数据
      listData: [],
      listPage: 1,
      listPageSize: 20,
      listTotal: 0,
      showTotal: 0,
      showAllKeyword: null,
      post_id: null,
      // 人工
      audit_type: 0,
    }
  },
  computed: {
    placeholder() {
      return this.search.search_type ? '请输入用户昵称' : '请输入评论内容'
    },
    commentInfo: {
      get() {
        return this.$store.state.comment
      },
      set(val) {
        this.$store.commit('COMMENT', val)
      },
    },
  },
  created() {
    this.getList()
    this.getRecovery()
    this.getAudit()
    if (this.commentInfo.post_id) {
      this.post_id = this.commentInfo.post_id
      this.keyword = this.commentInfo.keyword
      this.showSearch = this.commentInfo.keyword
      this.showSearch = true
      this.getList(true)
    } else {
      this.getList()
    }
  },
  methods: {
    async getAudit() {
      const { data } = await service.getAudit()
      this.audit_type = data.audit_type
    },
    async onAudit(_type) {
      await service.saveAudit({
        audit_type: _type,
      })
      this.getAudit()
    },
    async getList(type, isCurrent) {
      // 是否清除id  是否依然选择当前选择的
      try {
        const { data } = await service.list({
          page: this.page,
          page_size: this.pageSize,
          keyword: this.keyword,
          post_id: this.post_id,
        })
        this.data = data.record
        this.total = data.total
        if (data.record && data.record.length) {
          if (!isCurrent) {
            this.current = data.record[0]
            this.comment_status = this.current.comment_status === 1 ? true : false
          }
          this.getDetail()
        }
      } catch (e) {
      } finally {
        if (type) {
          this.post_id = null
          this.commentInfo = {}
        }
      }
    },
    async getDetail() {
      try {
        const params = {
          page: this.detailPage,
          page_size: this.detailPageSize,
          ...this.search,
        }
        const { data } = await service.getDetail({
          ...params,
          post_id: this.search.keyword ? '' : this.current.id,
        })
        this.detailData = data.record
        this.detailTotal = data.total
        if (!this.search.keyword) {
          await service.setRead({
            post_id: this.current.id,
          })
        }
      } catch (e) {}
    },
    async getSearchDetail() {
      try {
        const { data } = await service.getDetail({
          ...this.search,
          page: this.listPage,
          page_size: this.listPageSize,
        })
        this.listData = data.record
        this.listTotal = data.total
        this.showTotal = data.tatal_sum
      } catch (e) {}
    },
    onChoose(item) {
      this.current = item
      this.comment_status = this.current.comment_status === 1 ? true : false
      this.getDetail()
    },
    async getRecovery() {
      try {
        const { data } = await service.recycleTotal()
        this.recycleTotal = data
      } catch (e) {}
    },
    async onExamine(item, _type) {
      try {
        await service.examine({
          id: item.id,
          status: _type ? 1 : 2, // 审核状态1通过，2不通过
        })
        this.$message.success(_type ? '已将评论状态审核为通过。' : '已将评论状态审核为不通过。')
        if (!this.show) {
          this.getDetail()
        } else {
          this.getSearchDetail()
        }
      } catch (e) {}
    },
    async stateChange() {
      try {
        const { data } = await service.setStatus({
          post_id: this.current.id,
        })
        this.comment_status = data === 1 ? true : false
        this.$message.success('修改成功')
        this.getList(false, true)
      } catch (e) {
        this.getList(false, true)
      }
    },
    async onStar(item) {
      if (item.status !== 1) {
        this.$message.error('审核中和审核不通过的评论不允许置顶，请先处理审核状态。')
        return
      }
      if (item.is_spam === 1) {
        this.$message.error('垃圾评论不允许置顶，请先处理垃圾评论状态。')
        return
      }
      try {
        await service.setStar({
          id: item.id,
        })
        this.$message.success(item.is_star ? '已将评论取消置顶' : '已将评论置顶')
      } catch (e) {
      } finally {
        if (!this.show) {
          this.getDetail()
        } else {
          this.getSearchDetail()
        }
      }
    },
    async onDelete(item) {
      try {
        await service.recycleDelete({
          id: item.id,
        })
        this.$message.success('删除成功')
        this.detailPage = 1
      } catch (e) {
      } finally {
        if (!this.show) {
          this.getDetail()
        } else {
          this.getSearchDetail()
        }
        this.getRecovery()
        this.getList(false, true)
      }
    },
    async onSpam(item) {
      try {
        await service.spam({
          id: item.id,
          is_spam: item.is_spam ? 0 : 1, // 垃圾评论标记 0-否 1-是
        })
        this.$message.success('修改成功')
        if (!this.show) {
          this.getDetail()
        } else {
          this.getSearchDetail()
        }
      } catch (e) {}
    },
    onAllSearch() {
      if (this.search.keyword) {
        this.show = true
        this.showAllKeyword = this.search.keyword
        this.getSearchDetail()
      } else {
        this.getList()
        this.showAllKeyword = null
        this.show = false
      }
    },
    onSearch() {
      this.page = 1
      this.showKeyword = this.keyword
      this.getList()
    },
    refresh() {
      this.getList()
      this.getRecovery()
    },
  },
}
</script>

<style lang="less" module>
.home {
  padding-bottom: 20px;
  background: #fff;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 20px 20px;
    line-height: 32px;

    .title {
      margin-bottom: 0;
      font-size: 16px;
    }

    .btn {
      display: flex;
      align-items: center;
      float: right;

      .line {
        width: 1px;
        height: 20px;
        margin: 0 20px 0 42px;
        background-color: #eee;
      }

      .intellect {
        font-size: 14px;
        cursor: pointer;

        i {
          font-size: 20px;
          color: #000;
          vertical-align: -4px;
          margin-right: 6px;
        }
      }

      .linex {
        width: 1px;
        height: 20px;
        margin: 0 20px 0 20px;
        background-color: #eee;
      }

      .lineno {
        margin-left: 20px;
      }

      .input {
        width: 230px;
        margin-right: 10px;

        :global {
          .ant-form-item {
            margin-bottom: 0;
          }

          .ant-select-arrow {
            top: 40%;
          }

          .ant-form-item-control {
            line-height: 0;
          }

          .ant-input-affix-wrapper .ant-input:not(:last-child) {
            padding-right: 10px;
          }
        }
      }
    }
  }

  .content {
    display: flex;
    min-height: calc(100vh - 252px);
    margin: 0 20px 0 20px;
    border: 1px solid #eee;

    .left {
      width: 240px;
      border-right: 1px solid #eee;

      &-search {
        height: 64px;
        padding: 0 20px;
        color: #000;
        line-height: 64px;

        span {
          font-size: 16px;
        }

        i {
          float: right;
          font-size: 20px;
          vertical-align: -5;
        }
      }

      &-search-input {
        padding: 14px;

        :global {
          .ant-form-item {
            margin: 0;
          }
        }
      }

      &-box {
        min-height: calc(100vh - 340px);
        margin-bottom: 20px;
        overflow-y: auto;
      }

      &-item {
        margin-bottom: 2px;
        padding: 10px;
        border-left: 2px solid #fff;
        cursor: pointer;

        .name {
          margin-bottom: 12px;
        }

        .desc {
          margin-bottom: 0;
          font-size: 12px;

          .time {
            color: #5c5c5c;
          }

          .num {
            float: right;
          }
        }
      }

      .active {
        background: #fafafa;
        border-left: 2px solid @primary-color;

        .name {
          color: @primary-color;
        }
      }
    }

    .right {
      flex: 1;

      &-header {
        padding: 20px;

        .name {
          color: #000;
          font-size: 16px;
        }

        .btn {
          color: #000;
          font-size: 14px;
        }

        .switch {
          position: relative;
          top: -2px;
          margin-left: 10px;
        }
      }

      .table {
        .head {
          height: 48px;
          color: #000;
          line-height: 48px;
          background: #fafafa;

          :global {
            .ant-col {
              padding-left: 20px;
            }
          }
        }
      }

      .box {
        .children {
          margin-left: 60px;
          padding-left: 0;
        }

        &-item {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 17px 20px 15px;
          border-top: 1px solid #eee;

          &-avatar {
            flex: 1;
            color: @primary-color;

            img {
              width: 36px;
              height: 36px;
              margin-right: 10px;
              border-radius: 100%;
            }
          }

          &-btn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 300px;
            font-size: 13px;

            .state {
              color: #5c5c5c;
            }

            .collect {
              height: 20px;
              margin: 0 10px;
              padding: 0 10px;
              color: #bbb;
              font-size: 18px;
              text-align: left;
              border-right: 1px solid #eee;
              border-left: 1px solid #eee;

              i {
                position: relative;
                top: -4px;
                font-size: 17px;
              }
            }

            .examine {
              color: @primary-color;
              cursor: pointer;

              i {
                color: #5c5c5c;
                font-size: 17px;
                vertical-align: -4px;
              }
            }
          }

          &-content {
            width: 100%;
            margin: 3px 0 10px 0;
            padding-left: 46px;
            color: #000;
            font-size: 14px;

            p {
              margin-bottom: 0;
            }

            .error {
              color: @error-color;
            }
          }

          &-desc {
            width: 100%;
            padding-left: 46px;

            i {
              margin-right: 5px;
              color: @primary-color;
              font-size: 16px;
              vertical-align: -3px;
            }

            span {
              margin-right: 20px;
              color: #5c5c5c;
              font-size: 12px;
            }
          }

          .italic {
            font-style: italic;
          }
        }
      }
    }
  }

  .list {
    .title {
      margin-bottom: 0;
      padding-left: 20px;
      color: #5c5c5c;
      font-size: 12px;
    }

    .box {
      .children {
        margin-left: 60px;
        padding-left: 0;
      }

      &-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 20px 20px 0 20px;
        // border-top: 1px solid #eee;
        &-avatar {
          flex: 1;
          color: @primary-color;

          img {
            width: 36px;
            height: 36px;
            margin-right: 10px;
            border-radius: 100%;
          }
        }

        &-btn {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 300px;
          font-size: 13px;

          .state {
            color: #5c5c5c;
          }

          .collect {
            height: 20px;
            margin: 0 10px;
            padding: 0 10px;
            color: #bbb;
            font-size: 18px;
            text-align: left;
            border-right: 1px solid #eee;
            border-left: 1px solid #eee;

            i {
              position: relative;
              top: -4px;
              font-size: 17px;
            }
          }

          .examine {
            color: @primary-color;
            cursor: pointer;

            i {
              color: #5c5c5c;
              font-size: 17px;
              vertical-align: -4px;
            }
          }
        }

        &-content {
          width: 100%;
          margin: 3px 0 10px 0;
          padding-left: 46px;
          color: #000;
          font-size: 14px;

          p {
            margin-bottom: 0;
          }

          .error {
            color: @error-color;
          }
        }

        &-desc {
          width: 100%;
          margin-left: 46px;
          padding-bottom: 20px;
          border-bottom: 1px solid #eee;

          i {
            margin-right: 5px;
            color: @primary-color;
            font-size: 16px;
            vertical-align: -3px;
          }

          span {
            margin-right: 20px;
            color: #5c5c5c;
            font-size: 12px;
          }
        }

        .italic {
          font-style: italic;
        }
      }
    }
  }
}

.question {
  i {
    font-size: 16px;
    vertical-align: -3px;
  }
}
</style>
